import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = (props: any) => {
  const [isAuthenticated, setLoggedIn] = useState(true);
  useEffect(() => {
    (async () => {
      let user = null;
      try {
        user = await Auth.currentAuthenticatedUser();
        if (user) {
          console.log(user.username);
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      } catch (e) {
        setLoggedIn(false);
      }
    })();
  });
  return isAuthenticated ? <Outlet/> : <Navigate to="/signin"/>;

};
export default ProtectedRoute;