import * as campaign from './campaign';
import * as channel from './channel';
import * as publisher from './publisher';
import * as advertiser from './advertiser';

export default {
  campaign,
  channel,
  publisher,
  advertiser
};