import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import PlayIcon from '../img/play.png';
import DownloadIcon from '../img/download.png';

const axiosInstance = axios.create({
  baseURL: 'https://creative.gbads.net/creative'
});

const DownloadPlayCreative = ({fileName}) => {
  const [showModal, setShowModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
console.log(`Filename ${fileName}`);
  const handlePlay = (e) => {
    axiosInstance
      .get(`/path?file-name=${fileName}`)
      .then((res) => {
        setVideoUrl(res.data);
        setShowModal(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDownload = (e) => {
    axiosInstance
      .get(`/path?file-name=${fileName}`, {
        responseType: 'blob'
      })
      .then((response) => {
        const blob = new Blob([response.data], {type: 'video/mp4'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClose = () => setShowModal(false);

  return (
    <>
      <button className="download-play-button" data-format="csv" onClick={handlePlay}>
        <img src={PlayIcon} alt=""/>
      </button>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{fileName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video className="creative-video" controls autoPlay>
            <source src={videoUrl} type="video/mp4"/>
          </video>
        </Modal.Body>
        <Modal.Footer>
          <button className="primary-button" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <button  className="download-play-button" onClick={handleDownload}>
        <img src={DownloadIcon} alt=""/>
      </button>
    </>
  );
};

export default DownloadPlayCreative;
