import { textFilter } from 'react-bootstrap-table2-filter';

function columns (data) {
  return columns = [
    {
      dataField: 'campaignName',
      text: 'Campaign Name',
      headerStyle: {color: '#000000', background: '#F2F4F6'},
      filter: textFilter()
    },
    {
      dataField: 'lineItemName',
      text: 'Line Item Name',
      headerStyle: {color: '#000000', background: '#F2F4F6'}
    },
    {
      dataField: 'creativeName',
      text: 'Creative Name',
      headerStyle: {color: '#000000', background: '#F2F4F6'}
    },
    {
      dataField: 'opportunity',
      text: 'Opportunity',
      headerStyle: {color: '#000000', background: '#F2F4F6'}
    },
    {
      dataField: 'impression',
      text: 'Impression',
      headerStyle: {color: '#000000', background: '#F2F4F6'}
    },
    {
      dataField: 'revenue',
      text: 'Revenue',
      headerStyle: {color: '#000000', background: '#F2F4F6'}
    }
  ];

}

export default columns;