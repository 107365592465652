import React, { useState, useRef, useEffect } from 'react';
import useFetch from '../hooks/useFetch';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import ExportTable from './exportTable';
import uniqid from 'uniqid';
import { useLocation } from 'react-router-dom';
import schema from '../schema/index';
import DateTimePicker from './Date';
import moment from 'moment';
import DownloadPlayCreative from './DownloadPlay';

function Table () {
  const currentDate = moment().format('YYYY-MM-DD');
  const currentDateStart = moment().format('YYYY-MM');
  const location = useLocation();
  const page = location.pathname.replace('/', '');

  const tableRef = useRef(null);
  const dateFromRef = useRef(null);
  const dateToRef = useRef(null);

 // const [{data}, fetchData] = useFetch(`https://reporting-dev.gbads.net/reports/aggregate?type=${page.toUpperCase()}&date-time-from=${currentDateStart}-01+00:00:00&date-time-to=${currentDate}+23:59:59`);
  const [{data}, fetchData] = useFetch(`https://reporting.gbads.net/reports/aggregate?type=${page.toUpperCase()}&date-time-from=${currentDateStart}-01+00:00:00&date-time-to=${currentDate}+23:59:59`);
  const [filteredData, setFilteredData] = useState([]);

  const columns = schema[page].default;

  useEffect(() => {
    if (data !== null) {
      setFilteredData(data);
      console.log(data);
    }
  }, [data]);

  useEffect(() => {
    if (dateToRef.current) {
      dateToRef.current.clear();
    }
    if (dateFromRef.current) {
      dateFromRef.current.clear();
    }
  }, [page]);

  const handleDateTimeFilter = (startDateTime, endDateTime) => {
    //fetchData(`https://reporting-dev.gbads.net/reports/aggregate?type=${page.toUpperCase()}&date-time-from=${startDateTime}&date-time-to=${endDateTime}`);
    fetchData(`https://reporting.gbads.net/reports/aggregate?type=${page.toUpperCase()}&date-time-from=${startDateTime}&date-time-to=${endDateTime}`);
  };

  const handleTableChange = (type, {filters}) => {
    if (type === 'filter') {
      // apply filters to data and update state with new results
      const newData = data.filter(row => {
        let valid = true;
        for (const [key, value] of Object.entries(filters)) {
          valid = valid && row[key].includes(value.filterVal);
        }
        return valid;
      });
      setFilteredData(newData);
    }
  };

  return (
    <div className="App">
      <Header/>
      <Container fluid>
        {data && (

          <ToolkitProvider
            keyField={uniqid()}
            data={filteredData}
            columns={columns(data)}
          >
            {
              props => (
                <div className="search-bar">
                  <ExportTable page={page} trigger={filteredData}/>
                  <DateTimePicker dateFromRef={dateFromRef} dateToRef={dateToRef} handleDateTime={handleDateTimeFilter}/>
                  <BootstrapTable
                    ref={tableRef}
                    wrapperClasses="table-responsive"
                    pagination={paginationFactory({sizePerPage: 10})}
                    filter={filterFactory()}
                    onTableChange={handleTableChange}
                    filterPosition="top"
                    remote={{filter: true}}
                    bordered={true}
                    striped={false}
                    rowClasses="custom-row-class"
                    {...props.baseProps}
                  >
                  </BootstrapTable>
                </div>)}
          </ToolkitProvider>
        )}
      </Container>
      <Footer/>
    </div>
  );
}

export default Table;

