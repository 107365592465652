import React from 'react';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const SignOut = () => {
  const history = useNavigate();

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      console.log('Success!!', 'Logged out successfully!', 'success');
      history('/signin');
    } catch (error) {
      console.log('Error!!', error.message, 'danger');
    }
  };

  return (
    <div className="sign-out-wrap">
      <Button variant="contained" size="large" type="submit" onClick={handleLogout}>Logout</Button>
    </div>
  );
};

export default SignOut;
