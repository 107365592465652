import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {
  Alert,
  FormGroup,
  FormControl,
  FormLabel
} from 'react-bootstrap';

export default class ResetPassword extends Component {
  constructor (props) {
    super(props);

    this.state = {
      code: '',
      email: '',
      password: '',
      codeSent: false,
      confirmed: false,
      confirmPassword: '',
      isConfirming: false,
      isSendingCode: false
    };
  }

  validateCodeForm () {
    return this.state.email.length > 0;
  }

  validateResetForm () {
    return (
      this.state.code.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSendCodeClick = async event => {
    event.preventDefault();

    this.setState({isSendingCode: true});

    /*
        Auth.forgotPassword(this.state.email)
          .then(data => console.log(data))
          .catch(err => console.log(err));
    */

    try {
      await Auth.forgotPassword(this.state.email);
      this.setState({codeSent: true});
    } catch (e) {
      alert(e.message);
      this.setState({isSendingCode: false});
    }
  };

  handleConfirmClick = async event => {
    event.preventDefault();

    this.setState({isConfirming: true});

    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.code,
        this.state.password
      );
      this.setState({confirmed: true});
    } catch (e) {
      alert(e.message);
      this.setState({isConfirming: false});
    }
  };

  renderRequestCodeForm () {
    return (
      <form onSubmit={this.handleSendCodeClick}>
        <FormGroup bssize="large" controlId="email">
          <h5>Enter your Username</h5>
          <FormControl autoFocus type="text" value={this.state.email} onChange={this.handleChange}/>
        </FormGroup>
        <Button variant="contained" color="primary" size="large" type="submit" disabled={!this.validateCodeForm()}>Send Confirmation Code</Button>
      </form>
    );
  }

  renderConfirmationForm () {
    return (
      <form onSubmit={this.handleConfirmClick}>
        <FormGroup bssize="large" controlId="code">
          <FormLabel>Confirmation Code</FormLabel>
          <FormControl autoFocus type="tel" value={this.state.code} onChange={this.handleChange}
          />
          <Alert>Please check your email for the confirmation code.</Alert>
        </FormGroup>
        <hr/>
        <FormGroup bssize="large" controlId="password">
          <FormLabel>New Password</FormLabel>
          <FormControl type="password" value={this.state.password} onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup bssize="large" controlId="confirmPassword">
          <FormLabel>Confirm Password</FormLabel>
          <FormControl type="password" onChange={this.handleChange} value={this.state.confirmPassword}/>
        </FormGroup>
        <Button variant="contained" color="primary" size="large" type="submit" disabled={!this.validateResetForm()}>RESET PASSWORD</Button>
      </form>
    );
  }

  renderSuccessMessage () {
    return (
      <div className="success">
        <h5>Your password has been reset.</h5>
        <p><Link to="/signin">Click here to login with your new credentials.</Link></p>
      </div>
    );
  }

  render () {
    return (
      <div className="ResetPassword">
        {!this.state.codeSent
          ? this.renderRequestCodeForm()
          : !this.state.confirmed
            ? this.renderConfirmationForm()
            : this.renderSuccessMessage()}
      </div>
    );
  }
}
