import React, { useState } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import SignOut from './Logout';
import logoImg from '../img/logo.png';

function Header () {
  const [active, setActive] = useState('default');
  console.log('PRODUCTION');
  return (
    <Navbar bg="light" variant="light" expand="lg">
      <Container fluid>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <img src={logoImg} alt=""/>
          <Nav className="me-auto" variant="pills" defaultActiveKey="/">
            <Nav.Item>
              <NavLink to="/">Home</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink eventKey="link-1" to="/campaign">Campaign Report</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink eventKey="link-2" to="/channel">Channel Report</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink eventKey="link-3" to="/publisher">Publisher Report</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink eventKey="link-4" to="/advertiser">Advertiser Report</NavLink>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
        <SignOut/>
      </Container>
    </Navbar>

  );
}

export default Header;