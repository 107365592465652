import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { styled } from '@material-ui/core/styles';
import { useInput } from './../utils/forms';
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';

const Field = styled(TextField)({
  margin: '10px 0'
});

const Signup = () => {
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const {value: email, bind: bindEmail} = useInput('');
  const {value: password, bind: bindPassword} = useInput('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await Auth.signIn(email, password);
      history('/');
    } catch (error) {
      setErrorMessage(error.message);
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <Container>
      <Row>
        <div className="wrap-login">
          <form onSubmit={handleSubmit}>
            <h1>Welcome</h1>
            <Field label="User" {...bindEmail} type="text"/>
            <Field label="Password" type="password" {...bindPassword} />
            <br/>
            <div className="error">{errorMessage}</div>
            <br/>
            <Button variant="contained" color="primary" size="large" type="submit" disabled={loading}>
              {loading && <CircularProgress size={20} style={{marginRight: 20}}/>}
              LOGIN
            </Button>
          </form>
          <Link className="loginLink" to="/signin/reset">Forgot password?</Link>

        </div>
      </Row>
    </Container>

  );
};

export default Signup;