import homePic from '../img/welcome.png';
import Header from './Header';
import Footer from './Footer';
import React from 'react';

function Home () {
  return (
    <>
      <Header/>
      <div className="home-container">
        <img src={homePic} alt=""/>
      </div>
      <Footer/>
    </>
  );
}

export default Home;