import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Amplify from 'aws-amplify';
import Login from './components/Login';
import { COGNITO } from './configs/aws';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './components/Home';
import List from './components/List';
import ResetPassword from './components/ForgotPassword';

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID
});
const App = () => {
  return (
    <Router>
      <div className="App">
        <div className="content">
          <Routes>
            {/** Protected Routes */}
            {/** Wrap all Route under ProtectedRoutes element */}
            <Route path="/" element={<ProtectedRoute/>}>
              <Route path="" element={<Home/>}/>
              <Route path="/campaign" element={<List/>}/>
              <Route path="/channel" element={<List/>}/>
              <Route path="/publisher" element={<List/>}/>
              <Route path="/advertiser" element={<List/>}/>
            </Route>
            <Route path="/signin" element={<Login/>}/>
            <Route path="/signin/reset" element={<ResetPassword/>}/>
            {/*<Route path="/" element={<ProtectedRoute component={Dashboard}/>}/>*/}
          </Routes>
        </div>
      </div>
    </Router>
  );
};
export default App;