import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useCallback } from 'react';

const ExportTable = ({trigger, page}) => {

  const download = useCallback((e) => {
    Auth.currentSession()
      .then(({ accessToken }) => {

        const { campaignName, channelName } = trigger[0];
        const name = page === 'campaign' ? campaignName : page === 'channel' ? channelName : page === 'advertiser' ? channelName : campaignName;
        const format = e.target.getAttribute('data-format');
        const data = JSON.stringify(trigger);
        const date = new Date().toJSON().slice(0, 10).replace(/-/g, '');
        const report = page.toUpperCase();
        const url = `https://reporting.gbads.net/reports/export?type=${report}&file-name=campaigns/report-${name}-${date}.${format}`;

        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken.jwtToken}`
          },
          responseType: 'blob',
          data
        };

        axios(config)
          .then(({ data }) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report-${name}-${date}.${format}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.log(error);
          });

      })
      .catch((error) => {
        console.error(error);
      });
  }, [trigger, page]);

  return (
    <div className="export-buttons">
      <button className="primary-button" data-format="csv" onClick={download}>export csv</button>
      <button className="primary-button" data-format="xlsx" onClick={download}>export xlsx</button>
    </div>
  );
};

export default ExportTable;
