import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import DownloadPlayCreative from '../components/DownloadPlay';

function columns (data) {

  //MERGE SAME ORIGINS TO GET UNIQUE ONE
  const uniqueChannel = data.filter((v, i, a) => a.findIndex(t => (t.channelName === v.channelName)) === i);
  const test = uniqueChannel.map((item, index) => item.channelName);
  const obj = {...test};

  const convertArrayToObject = (array) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item]: item
      };
    }, initialValue);
  };

  const selectOptions = convertArrayToObject(test);

  return columns = [{
    dataField: 'channelName',
    text: 'Select Channel Name',
    headerStyle: {color: '#000000', background: '#F2F4F6'},
    formatter: cell => selectOptions[cell],
    filter: selectFilter({
      options: selectOptions,
      placeholder: 'Select Channel Name'

    })
  },
    {
      dataField: 'creativeName',
      text: 'Creative Name',
      headerStyle: {color: '#000000', background: '#F2F4F6'},
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          {cell}<DownloadPlayCreative fileName={cell}/>
        </>
      )
    },
    {
      dataField: 'opportunity',
      text: 'Opportunity',
      headerStyle: {color: '#000000', background: '#F2F4F6'}
    },
    {
      dataField: 'impression',
      text: 'Impression',
      headerStyle: {color: '#000000', background: '#F2F4F6'}
    },
    {
      dataField: 'revenue',
      text: 'Revenue',
      headerStyle: {color: '#000000', background: '#F2F4F6'}
    }
  ];

}

export default columns;