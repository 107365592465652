import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';

const useFetch = (apiPathDefault) => {
  const [data, setData] = useState(null);

  // Just pass the variables that changes in each new fetch requisition
  const fetchData = (apiPath) => {
    Auth.currentSession()
      .then((response) => {
        axios(apiPath, {
          headers: {
            'Authorization': `Bearer ${response.accessToken.jwtToken}`
          }
        })
          .then((res) => {
            setData(res.data);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData(apiPathDefault);
  }, [apiPathDefault]);

  return [{data}, fetchData];

};

export default useFetch;