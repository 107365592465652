import React, { useState, useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';


function DateTimePicker ({ handleDateTime, dateFromRef, dateToRef }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const currentDate = useMemo(() => moment().format('YYYY-MM-DD'), []);
  const currentDateStart = useMemo(() => moment().format('YYYY-MM'), []);

  const handleSubmit = useCallback(() => {
    const startDateTime = moment(startDate).format('YYYY-MM-DD+HH:mm:ss');
    const endDateTime = moment(endDate).format('YYYY-MM-DD+HH:mm:ss');
    handleDateTime(startDateTime, endDateTime);
  }, [startDate, endDate, handleDateTime]);

  return (
    <div className="date-filter">
      <div className="picker">
        <h6>Date/Time From:</h6>
        <DatePicker
          ref={dateFromRef}
          selected={startDate}
          onChange={date => setStartDate(date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={60}
          dateFormat="MMMM d, yyyy h:mm aa"
          timeCaption="time"
          placeholderText={`${currentDateStart}-01`}
        />
      </div>
      <div className="picker">
        <h6>Date/Time To:</h6>
        <DatePicker
          ref={dateToRef}
          selected={endDate}
          onChange={date => setEndDate(date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={60}
          dateFormat="MMMM d, yyyy h:mm aa"
          timeCaption="time"
          placeholderText={`${currentDate}`}
          minDate={startDate}
        />
      </div>
      <button className="primary-button" onClick={handleSubmit}>Search</button>
    </div>
  );
}

export default DateTimePicker;
